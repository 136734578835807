import React, { useContext, useState } from 'react'
import { Box, Container, Grid, Icon, IconButton, Paper, Stack, Typography } from '@mui/material'
import InputText from '../../../DevComponents/InputText'
import { ContextoGlobal, ContextoGlobalInterface } from '../../../GlobalStates/ContextoGlobal'
import Condicional from '../../../Layout/Condicional'
import Button from '@mui/material/Button'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'

import DataTable, { DataTableCabecalhoInterface } from '../../../DevComponents/DataTable'
import { useNavigate } from 'react-router-dom'
import ClsCrud, { StatusForm } from '../../../Utils/ClsCrud'

import ClsValidacao from '../../../Utils/ClsValidacao'
import { AtualizacaoCadastroProdutorInterface, rsPesquisaProdutorRespostaInterface } from '../../../ImportBackend/Interfaces/AtualizacaoCadastroInterfaces'
import RadioButton from '../../../DevComponents/RadioButton'
import { TipoClienteType, TipoClienteTypes } from '../../../ImportBackend/types/ConstantesDataTypes'
import BackEndAPI from '../../../Services/BackEndAPI'
import ExibirJSONDev from '../../../DevComponents/ExibirJSONDev'
// import AtualizarCadastroProdutorPropriedade from './AtualizarCadastroProdutorPropriedade'
import ClsAcesso from '../../../Utils/ClsAcesso'
import { SISTEMA_PERMISSOES } from '../../../ImportBackend/types/AcessosDataTypes'
import PesquisarTabela from '../../../DevComponents/PesquisarTabela'
import { PedidoInterface, rsERPPessoaAutorizadaAComprarInterface, rsERPUnidadeFaturamentoInterface, rsERPNaturezaOperacaoInterface, rsERPCondicaoPagamentoInterface, PedidoItemInterface, rsERPLimiteCreditoInterface, rsIncluirPedidoInterface } from '../../../ImportBackend/Interfaces/PedidoInterfaces'
import { UsuarioInterface } from '../../../ImportBackend/Interfaces/UsuarioInterfaces'
import ShowText from '../../../DevComponents/ShowText'
import ComboBox from '../../../DevComponents/ComboBox'
import { EMDESENVOLVIMENTO } from '../../../ImportBackend/Config/emDesenvolvimento'
import PedidosItem from './PedidosItem'
import { clsUtils } from 'zlib-utils'
import { MensagemTipo } from '../../../GlobalStates/MensagemState'
import ClsFormatacao from '../../../Utils/ClsFormatacao'
import { RespostaPadraoInterface } from '../../../ImportBackend/Interfaces/PadraoInterfaces'

const CONDICAO_PAGAMENTO_PADRAO: number = 1
const NATUREZA_OPERACAO: number = 34

interface PesquisaInterface {
  descricao: string
}

export interface ProdutorParaEditarPropriedadeInterface {
  idProdutor: number
  nome: string
}

export default function Pedidos () {

  const ResetERPUnidadeFaturamento: rsERPUnidadeFaturamentoInterface = {
    ID_UNIDADE_FAT_CLIENTE: 0,
    ID_CLIENTE: 0,
    CODIGO_CLIENTE: 0,
    ID_PESSOA: 0,
    NOME_FANTASIA: '',
    NOME: '',
    LOGRADOURO: '',
    NUMERO: '',
    BAIRRO: '',
    CIDADE: '',
    CNPJ: '',
    INSCRICAO_ESTADUAL: '',
    ATIVO: true
  }

  const ResetERPLimiteCredito: rsERPLimiteCreditoInterface = {
    ID_CLIENTE: 0,
    HABIL_PARA_COMPRA: false,
    NAO_AVALIAR_FINANCEIRO: false,
    LIMITE_CREDITO: 0,
    DIAS_CARENCIA_SALDO_VENC: 0,
    VR_TIT_VENC_FOR_CAR: 0,
    VR_TITULOS_EM_ABERTO: 0,
    CONDICOES_PAGAMENTO: []
  }

  const [rsERPUnidadeFaturamento, setRsERPUnidadeFaturamento] = useState<rsERPUnidadeFaturamentoInterface>( ResetERPUnidadeFaturamento )

  const abortController: AbortController = new AbortController()

  const [statusForm, setStatusForm] = useState<StatusForm>( StatusForm.Pesquisando )

  const [rsPessoasAutorizadasComprar, setRsPessoasAutorizadasComprar] = useState<Array<rsERPPessoaAutorizadaAComprarInterface>>( [] )
  const [rsLimiteCredito, setRsLimiteCredito] = useState<rsERPLimiteCreditoInterface>( ResetERPLimiteCredito )

  const [rsPedidoItens, setRsPedidoItens] = useState<Array<PedidoItemInterface>>( [] )

  const [rsExibirDados, setRsExibirDados] = useState<boolean>( true )

  const clsFormato: ClsFormatacao = new ClsFormatacao()

  const Cabecalho: Array<DataTableCabecalhoInterface> = [
    {
      campo: 'idPedidoERP',
      cabecalho: 'ERP',
      alinhamento: 'left'
    },
    {
      campo: 'observacao',
      cabecalho: 'observacao',
      alinhamento: 'left'
    },
    {
      campo: 'vrBruto',
      cabecalho: 'Vr. Bruto',
      alinhamento: 'right',
      format: ( rs ) => clsFormato.currency( rs )
    },
    {
      campo: 'perDesconto',
      cabecalho: '% Desconto',
      alinhamento: 'right',
      format: ( rs ) => clsFormato.currency( rs )
    },
    {
      campo: 'vrDesconto',
      cabecalho: 'Vr. Desconto',
      alinhamento: 'right',
      format: ( rs ) => clsFormato.currency( rs )
    },
    {
      campo: 'perAcrescimo',
      cabecalho: '% Acréscimo',
      alinhamento: 'right',
      format: ( rs ) => clsFormato.currency( rs )
    },
    {
      campo: 'vrAcrescimo',
      cabecalho: 'Vr. Acréscimo',
      alinhamento: 'right',
      format: ( rs ) => clsFormato.currency( rs )
    },
    {
      campo: 'vrLiquido',
      cabecalho: 'Vr. Líquido',
      alinhamento: 'right',
      format: ( rs ) => clsFormato.currency( rs )
    },
    /*
    vrBruto perDesconto vrDesconto perAcrescimo vrAcrescimo vrLiquido
  {
    campo: 'fone',
    cabecalho: 'Fone',
    alinhamento: 'left'
  },
  {
    campo: 'associado',
    cabecalho: 'Associado',
    alinhamento: 'left',
    format: ( v ) => v ? 'Sim' : 'Não'
  },
  {
    campo: 'codigocliente',
    cabecalho: 'Código Cliente',
    alinhamento: 'left'
  },
  {
    campo: 'Usuario',
    cabecalho: 'Responsável',
    alinhamento: 'left',
    format: ( v: UsuarioInterface ) => v.nome
  },*/
  ]

  const contexto = useContext( ContextoGlobal ) as ContextoGlobalInterface

  const ResetDados: PedidoInterface = EMDESENVOLVIMENTO ? {
    idPessoaAutorizadaComprar: 0,
    idUnidadeFaturamento: 0,
    idCliente: 0,
    idCondicaoPagamento: CONDICAO_PAGAMENTO_PADRAO,
    idNaturezaOperacao: NATUREZA_OPERACAO,
    observacao: '',
    perDesconto: 0,
    vrDesconto: 0,
    perAcrescimo: 0,
    vrAcrescimo: 0,
    vrBruto: 0,
    vrLiquido: 0,
    pedidoItens: [],
    idPedidoERP: 0,
    idEmpresa: 0,
    idRepresentante: contexto.loginState.idRepresentante
  } : {
    idPessoaAutorizadaComprar: 0,
    idUnidadeFaturamento: 0,
    idCliente: 0,
    idCondicaoPagamento: CONDICAO_PAGAMENTO_PADRAO,
    idNaturezaOperacao: NATUREZA_OPERACAO,
    observacao: '',
    perDesconto: 0,
    vrDesconto: 0,
    perAcrescimo: 0,
    vrAcrescimo: 0,
    vrBruto: 0,
    vrLiquido: 0,
    pedidoItens: [],
    idPedidoERP: 0,
    idEmpresa: 0,
    idRepresentante: contexto.loginState.idRepresentante
  }


  const TituloForm = {
    [StatusForm.Incluindo]: 'Inclusão de Pedido',
    [StatusForm.Excluindo]: 'Exclusão de Pedido',
    [StatusForm.Pesquisando]: 'Pesquisa de Pedidos',
    [StatusForm.Editando]: 'Alteração de Dados de Pedido',
    [StatusForm.Exibindo]: 'Dados do Pedido'
  }

  const { mensagemState, setMensagemState } = contexto

  const [dados, setDados] = useState<PedidoInterface>( ResetDados )

  const [erros, setErros] = useState<Record<string, string>>( {} )

  const [pesquisa, setPesquisa] = useState<PesquisaInterface>( { descricao: '' } )
  const [mensagemAvisoFinanceiro, setMensagemAvisoFinanceiro] = useState<string>( '' )

  const [rsPesquisa, setRsPesquisa] = useState<Array<PedidoInterface>>( [] )

  const clsApi = new BackEndAPI()

  const navigate = useNavigate()

  const validarDados = (): boolean => {

    let retorno: boolean = true
    let erros: { [key: string]: string } = {}

    let clsValidacao = new ClsValidacao()

    retorno = clsValidacao.naoVazio( 'idPessoaAutorizadaComprar', dados, erros, retorno )
    retorno = clsValidacao.naoVazio( 'idUnidadeFaturamento', dados, erros, retorno )
    retorno = clsValidacao.naoVazio( 'idNaturezaOperacao', dados, erros, retorno )
    retorno = clsValidacao.naoVazio( 'idCondicaoPagamento', dados, erros, retorno )
    retorno = clsValidacao.naoVazio( 'idCliente', dados, erros, retorno )

    if ( rsPedidoItens.length === 0 ) {
      retorno = false
      erros = { ...erros, Itens: 'Adicione no mínimo um produto' }
    }

    setErros( erros )

    return retorno

  }

  const clsCrud: ClsCrud<PedidoInterface> = new ClsCrud(
    navigate,
    ResetDados,
    setStatusForm,
    setDados,
    setErros,
    mensagemState,
    setMensagemState,
    setRsPesquisa,
    contexto,
    validarDados,
    {
      confirmarMutation: 'updatePedido',
      excluirMutation: 'delPedido',
      campoId: 'idPedido',
      camposPesquisa: '{idPedidoERP idRepresentante idUnidadeFaturamento idPessoaAutorizadaComprar idNaturezaOperacao idCliente idCondicaoPagamento observacao vrBruto perDesconto vrDesconto perAcrescimo vrAcrescimo vrLiquido idEmpresa}',
      pesquisaQuery: 'getPedidos',
      pesquisaPorId: 'getPedidoPorId',
      camposPesquisaPorId: '{idPedidoERP idRepresentante idUnidadeFaturamento idPessoaAutorizadaComprar idNaturezaOperacao idCliente idCondicaoPagamento observacao vrBruto perDesconto vrDesconto perAcrescimo vrAcrescimo vrLiquido idEmpresa}'
    },
    {
      confirmando: 'Atualizando Pedido',
      erroCadastro: 'Erro ao Cadastrar Pedido',
      erroExclusao: 'Erro ao Excluir Pedido',
      erroPesquisa: 'Erro ao Pesquisar Pedido',
      pesquisando: 'Pesquisando Dados de Pedidos...',
      sucessoCadastro: 'Pedido Cadastrado com sucesso!',
      atualizacaoSucesso: 'Pedido Atualizado com sucesso!',
      tituloConfirmado: 'Confirmado!',
      sucessoExclusao: 'Pedido Excluído com sucesso...',
      tituloConfirmacaoExclusao: 'Confirma?',
      tituloErroCadastro: 'Erro!',
      tituloErroExclusao: 'Erro!',
      tituloErroPesquisa: 'Erro!',
      excluindo: 'Excluindo Pedido...'
    }
  )

  const onChangeUnidadeFaturamento = ( rs: rsERPUnidadeFaturamentoInterface ) => {

    if ( rs && rs.ID_UNIDADE_FAT_CLIENTE ) {
      setRsERPUnidadeFaturamento( rs )
      pesquisarPessoasAutorizadas( rs.ID_UNIDADE_FAT_CLIENTE, rs.ID_CLIENTE ).then( () => {
        pesquisarLimiteFinanceiro( rs.ID_CLIENTE ).then( () => {
          // Pesquisa pela Padrão pois dentro do pesquisarPessoasAutorizadas a condição é setada para padrão
          atualizarValoresDeProdutos( rs.ID_UNIDADE_FAT_CLIENTE, CONDICAO_PAGAMENTO_PADRAO )
        } )
      } )
    } else {
      setRsERPUnidadeFaturamento( ResetERPUnidadeFaturamento )
      setDados( ResetDados )
      setRsPessoasAutorizadasComprar( [] )
      setRsLimiteCredito( ResetERPLimiteCredito )
    }

  }

  const onChangeCondicaoPagamento = ( rs: rsERPCondicaoPagamentoInterface ) => {

    if ( rs && rs.ID_CONDICAO_PAGAMENTO ) {
      setDados( { ...dados, idCondicaoPagamento: rs.ID_CONDICAO_PAGAMENTO } )
      if ( dados.idUnidadeFaturamento > 0 ) {
        atualizarValoresDeProdutos( dados.idUnidadeFaturamento, rs.ID_CONDICAO_PAGAMENTO )
      }
    }

  }

  const pesquisarPessoasAutorizadas = ( idUnidadeFatCliente: number, idCliente: number ): Promise<() => void> => {
    const query: string = `
      getERPPessoasAutorizadasAComprar (ID_CLIENTE: ${idCliente}) {
        ID_PESSOA
        NOME
      }
    `

    return clsApi.query<Array<rsERPPessoaAutorizadaAComprarInterface>>( query, 'getERPPessoasAutorizadasAComprar', 'Pesquisando Autorizados a Comprar...', contexto, abortController ).then( ( rs ) => {

      setRsPessoasAutorizadasComprar( rs )

      if ( rs.length >= 1 ) {
        setDados( { ...dados, idUnidadeFaturamento: idUnidadeFatCliente, idPessoaAutorizadaComprar: rs[0].ID_PESSOA, idCliente: idCliente, idCondicaoPagamento: CONDICAO_PAGAMENTO_PADRAO } )
      } else {
        setDados( { ...dados, idUnidadeFaturamento: idUnidadeFatCliente, idPessoaAutorizadaComprar: 0, idCliente: idCliente, idCondicaoPagamento: CONDICAO_PAGAMENTO_PADRAO } )
      }

      return () => {
        abortController.abort()
      }

    } )
  }

  const pesquisarLimiteFinanceiro = ( idCliente: number ): Promise<() => void> => {
    const query: string = `
      getERPLimiteCredito (ID_CLIENTE: ${idCliente}) {
        ID_CLIENTE
        HABIL_PARA_COMPRA
        NAO_AVALIAR_FINANCEIRO
        LIMITE_CREDITO
        DIAS_CARENCIA_SALDO_VENC
        VR_TIT_VENC_FOR_CAR
        VR_TITULOS_EM_ABERTO
        CONDICOES_PAGAMENTO {
          ID_CONDICAO_PAGAMENTO
          DESCRICAO
        }
      }
    `

    return clsApi.query<rsERPLimiteCreditoInterface>( query, 'getERPLimiteCredito', 'Pesquisando Limites de Crédito...', contexto, abortController ).then( ( rs ) => {

      let mensagem: string = ''

      if ( !rs.HABIL_PARA_COMPRA ) {
        mensagem = 'Cliente inabilitado para compra. '
      }

      if ( !rs.NAO_AVALIAR_FINANCEIRO ) {
        if ( rs.VR_TIT_VENC_FOR_CAR > 0 ) {
          mensagem = mensagem.concat( 'Títulos vencidos. Condição de pagamento somente a vista. ' )
        }

        if ( rs.VR_TITULOS_EM_ABERTO > rs.LIMITE_CREDITO ) {
          mensagem = mensagem.concat( 'Limite de compra excedido. Sujeito a análise financeira. ' )
        }
      }

      setMensagemAvisoFinanceiro( mensagem )
      setRsLimiteCredito( rs )

      return () => {

        abortController.abort()
      }

    } )
  }

  const atualizarValoresDeProdutos = ( idUnidadeFaturamento: number, idCondicaoPagamento: number ) => {

    if ( idUnidadeFaturamento > 0 && idCondicaoPagamento > 0 && rsPedidoItens.length > 0 ) {

      const query: string = `
        getERPAtualizarValoresItensPedido (
          rsPedidosItens: ${clsUtils.ConverterEmGql( rsPedidoItens )},
          idUnidadeFaturamento: ${idUnidadeFaturamento},
          idRepresentante: ${contexto.loginState.idRepresentante},
          idCondicaoPagamento: ${idCondicaoPagamento}
        ) {
          idPedido
          idEmpresa
          idCentroEstoque
          descricaoCentroEstoque
          descricaoProduto
          idProduto
          perDesconto
          vrDesconto
          perAcrescimo
          vrAcrescimo
          vrUnitario
          vrTotal
          saldoEstoque
          permitirEstoqueNegativo
          perComissao
          quantidade
          desconto
          vrSugerido
          vrMinimo
          vrMaximo
          idUnidadeMedida
        }
      `

      clsApi.query<Array<PedidoItemInterface>>( query, 'getERPAtualizarValoresItensPedido', 'Atualizando Valores de Produtos...', contexto, abortController ).then( ( rs ) => {

        setRsPedidoItens( rs )

      } ).catch( ( e ) => {

        setRsPedidoItens( [] )

        setMensagemState( {
          cb: null,
          exibir: true,
          exibirBotao: true,
          mensagem: 'Erro na Consulta do ERP - '.concat( e ),
          tipo: MensagemTipo.Error,
          titulo: 'Erro ERP!'
        } )

      } )

      return () => {

        abortController.abort()
      }

    }

  }

  const onConfirmarPedido = () => {

    if ( validarDados() ) {

      const rsPedido: PedidoInterface = { ...dados, pedidoItens: [...rsPedidoItens] }

      const mutation: string = `
        incluirPedido (
          pedido: ${clsUtils.ConverterEmGql( rsPedido )}) {
            idPedido
            idEmpresa
          }         
      `

      clsApi.mutation<Array<rsIncluirPedidoInterface>>( mutation, 'incluirPedido', 'Incluindo Pedido...', contexto, abortController ).then( ( rsPedidosIncluidos ) => {

        // Exclui Itens que foram incluídos com sucesso - restará os com problemas que ficarão na mesma tela do usuário

        let tmpPedidoItens: Array<PedidoItemInterface> = []

        rsPedidoItens.forEach( rs => {
          const indice = rsPedidosIncluidos.findIndex( v => v.idEmpresa === rs.idEmpresa )

          if ( indice < 0 ) {
            tmpPedidoItens.push( rs )
          }

        } )

        if ( tmpPedidoItens.length > 0 ) {
          setRsPedidoItens( tmpPedidoItens )
          onTotalizarPedido( tmpPedidoItens )

          setMensagemState( {
            cb: null,
            exibir: true,
            exibirBotao: true,
            mensagem: 'Erro na Inclusão do Pedido no ERP. Foram Incluídos os pedidos: '.concat( rsPedidosIncluidos.toString() ),
            tipo: MensagemTipo.Error,
            titulo: 'Erro ERP!'
          } )

        } else {

          setDados( ResetDados )
          setStatusForm( StatusForm.Pesquisando )
          setRsPedidoItens( [] )

          const mensagem = rsPedidosIncluidos.length > 1 ? 'Pedidos incluídos no ERP: ' : 'Pedido '.concat( rsPedidosIncluidos[0].toString(), ' incluído no ERP.' )

          setMensagemState( {
            cb: null,
            exibir: true,
            exibirBotao: true,
            mensagem: mensagem,
            tipo: MensagemTipo.Info,
            titulo: 'Inclusão OK!'
          } )

        }

      } ).catch( ( e ) => {

        setMensagemState( {
          cb: null,
          exibir: true,
          exibirBotao: true,
          mensagem: 'Erro na Inclusão do Pedido no ERP - '.concat( e ),
          tipo: MensagemTipo.Error,
          titulo: 'Erro ERP!'
        } )

      } )

      return () => {

        abortController.abort()
      }


    }

  }

  const onTotalizarPedido = ( rs: Array<PedidoItemInterface> ) => {

    let totalizador = {
      perDesconto: 0,
      vrDesconto: 0,
      perAcrescimo: 0,
      vrAcrescimo: 0,
      vrBruto: 0,
      vrLiquido: 0
    }

    rs.forEach( v => {
      totalizador.vrDesconto += v.vrDesconto * v.quantidade
      totalizador.vrAcrescimo += v.vrAcrescimo * v.quantidade
      totalizador.vrBruto += parseFloat( ( ( v.vrUnitario ) * v.quantidade ).toFixed( 2 ) )
    } )

    totalizador.vrLiquido = parseFloat( ( totalizador.vrBruto + totalizador.vrAcrescimo - totalizador.vrDesconto ).toFixed( 2 ) )
    totalizador.perDesconto = parseFloat( ( totalizador.vrDesconto / totalizador.vrBruto * 100 ).toFixed( 2 ) )
    totalizador.perAcrescimo = parseFloat( ( totalizador.vrAcrescimo / totalizador.vrBruto * 100 ).toFixed( 2 ) )

    setDados( { ...dados, ...totalizador } )

  }

  return (
    <>
      <Container maxWidth="lg" sx={{ mt: 5, }}>

        <Grid container sx={{ display: 'flex', alignItems: 'stretch' }}>

          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
            <Typography component="h5" variant="h5" align="left">
              Cadastro de Pedidos
              <Typography variant="body2" gutterBottom>
                {TituloForm[statusForm]}
              </Typography>
            </Typography>

            <IconButton onClick={() => clsCrud.btFechar()}>
              <CloseIcon />
            </IconButton>
          </Grid>

          <Condicional condicao={statusForm === StatusForm.Pesquisando}>


            <Grid item xs={12} sm={10} sx={{ mb: 3 }}>

              <InputText
                dados={pesquisa}
                field='descricao'
                label='Pesquisar'
                setState={setPesquisa}
                iconeEnd="search"
                onClickIconeEnd={() => clsCrud.onClickPesquisa( pesquisa.descricao, mensagemState )}
              // mapKeyPress={[{ key: 'Enter', onKey: onKeyPesquisa }]}
              />

            </Grid>

            <Grid item xs={12} sm={2} alignSelf='center' sx={{ mt: { xs: 0, sm: 2 }, textAlign: { xs: 'right', sm: 'center' } }}>
              <Button variant='contained' disabled={contexto.loginState.idRepresentante === 0} onClick={() => clsCrud.btIncluir()}>Incluir</Button>
            </Grid>

          </Condicional>

          <Condicional condicao={statusForm !== StatusForm.Pesquisando}>

            <Grid item xs={11}>

              <PesquisarTabela<rsERPUnidadeFaturamentoInterface>
                setState={setDados}
                field='ID_UNIDADE_FAT_CLIENTE'
                fieldSet='idUnidadeFaturamento'
                label='Cliente'
                dados={dados}
                campoQueryPesquisaID='ID_UNIDADE_FAT_CLIENTE'
                campoQueryPesquisa='pesquisa'
                camposRetornoQueryPesquisa='{ID_UNIDADE_FAT_CLIENTE ID_PESSOA ID_CLIENTE CODIGO_CLIENTE NOME NOME_FANTASIA LOGRADOURO NUMERO BAIRRO CIDADE CNPJ INSCRICAO_ESTADUAL ATIVO}'
                campoLabelQueryPesquisa='NOME'
                nomeQueryPesquisa='getERPUnidadeFaturamento'
                nomeQueryPesquisaID='getERPUnidadeFaturamentoPorId'
                mensagemPesquisa='Procurando Unidades de Faturamento...'
                disabled={statusForm === StatusForm.Excluindo}
                erros={erros}
                groupBy={( rs ) => rs.NOME}
                camposParaExibir={['CODIGO_CLIENTE', 'NOME', 'NOME_FANTASIA', 'LOGRADOURO', 'CIDADE', 'INSCRICAO_ESTADUAL']}
                onChange={( rs: rsERPUnidadeFaturamentoInterface ) => onChangeUnidadeFaturamento( rs )}
                valorAtribuirLimpar={''}
                inputUpperCase
              />

            </Grid>

            <Grid item xs={1} sx={{ mt: 5, textAlign: 'right' }}>
              <IconButton onClick={() => setRsExibirDados( !rsExibirDados )} >
                <Condicional condicao={!rsExibirDados}>
                  <Icon>visibility</Icon>
                </Condicional>
                <Condicional condicao={rsExibirDados}>
                  <Icon>visibility_off</Icon>
                </Condicional>
              </IconButton>
            </Grid>

            <Condicional condicao={rsExibirDados}>

              <Condicional condicao={mensagemAvisoFinanceiro.length > 0}>
                <Grid item xs={12}>
                  <Typography variant="subtitle2" color={'red'}>
                    {mensagemAvisoFinanceiro}
                  </Typography>
                </Grid>
              </Condicional>

              <Grid item xs={12} md={2}>
                <ShowText
                  dados={rsERPUnidadeFaturamento}
                  field='CODIGO_CLIENTE'
                  label='Código Cliente'
                />
              </Grid>

              <Grid item xs={12} md={7} sx={{ pl: { md: 1 } }}>
                <ShowText
                  dados={rsERPUnidadeFaturamento}
                  field='NOME_FANTASIA'
                  label='Nome Fantasia'
                />
              </Grid>

              <Grid item xs={12} md={3} sx={{ pl: { md: 1 } }}>
                <ShowText
                  dados={rsERPUnidadeFaturamento}
                  field='INSCRICAO_ESTADUAL'
                  label='Inscrição Estadual'
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <ShowText
                  dados={rsERPUnidadeFaturamento}
                  field='CNPJ'
                  label='CPF/CNPJ'
                />
              </Grid>

              <Grid item xs={12} md={5} sx={{ pl: { md: 1 } }}>
                <ShowText
                  dados={rsERPUnidadeFaturamento}
                  field='LOGRADOURO'
                  label='Logradouro'
                />
              </Grid>

              <Grid item xs={12} md={4} sx={{ pl: { md: 1 } }}>
                <ShowText
                  dados={rsERPUnidadeFaturamento}
                  field='CIDADE'
                  label=' Cidade'
                />
              </Grid>

            </Condicional>

            <Grid item xs={12} md={6}>

              <ComboBox
                setState={setDados}
                campoDescricao='NOME'
                campoID='ID_PESSOA'
                dados={dados}
                field='idPessoaAutorizadaComprar'
                label='Pessoa Autorizada'
                opcoes={rsPessoasAutorizadasComprar}
              />

            </Grid>

            <Grid item xs={12} md={3} sx={{ pl: { md: 1 } }}>
              <PesquisarTabela<rsERPNaturezaOperacaoInterface>
                setState={setDados}
                field='ID_NATUREZA_OPERACAO'
                fieldSet='idNaturezaOperacao'
                label='Natureza Operação'
                dados={dados}
                campoQueryPesquisaID='ID_NATUREZA_OPERACAO'
                campoQueryPesquisa='pesquisa'
                camposRetornoQueryPesquisa='{ID_NATUREZA_OPERACAO DESCRICAO}'
                campoLabelQueryPesquisa='DESCRICAO'
                nomeQueryPesquisa='getERPNaturezaOperacao'
                nomeQueryPesquisaID='getERPNaturezaOperacaoPorId'
                mensagemPesquisa='Procurando Natureza da Operação'
                disabled={statusForm === StatusForm.Excluindo}
                erros={erros}
                inputUpperCase
                // groupBy={( unidade ) => unidade.NOME}
                // onChange={( rs: rsERPNaturezaOperacaoInterface ) => setDados( { ...dados, idNaturezaOperacao: rs.ID_NATUREZA_OPERACAO } )}
                valorAtribuirLimpar={''}
              />

            </Grid>

            <Grid item xs={12} md={3} sx={{ pl: { md: 1 } }}>

              <ComboBox
                setState={setDados}
                opcoes={rsLimiteCredito.CONDICOES_PAGAMENTO}
                campoDescricao='DESCRICAO'
                campoID='ID_CONDICAO_PAGAMENTO'
                dados={dados}
                field='idCondicaoPagamento'
                label='Condição de Pagamento'
                mensagemPadraoCampoEmBranco='Selecione Condição de Pagamento'
                permitirNovaOpcao={false}
                onChange={( rs: rsERPCondicaoPagamentoInterface ) => onChangeCondicaoPagamento( rs )}
              />
            </Grid>

            <Grid item xs={12}>
              <InputText
                dados={dados}
                erros={erros}
                tipo='uppercase'
                field='observacao'
                label='Observação'
                setState={setDados}
              />
            </Grid>

          </Condicional>

          <Condicional condicao={dados.idUnidadeFaturamento > 0 && dados.idCondicaoPagamento > 0}>
            <PedidosItem
              idCondicaoPagamento={dados.idCondicaoPagamento}
              idUnidadeFaturamento={dados.idUnidadeFaturamento}
              rsPedidoItens={rsPedidoItens}
              setRsPedidoItens={setRsPedidoItens}
              onTotalizarPedido={onTotalizarPedido}
            />
            <Condicional condicao={typeof erros.Itens !== 'undefined'}>
              <Typography variant='caption' textAlign='left' color='warning.main' >{erros.Itens}</Typography>
            </Condicional>
          </Condicional>

          <Condicional condicao={statusForm === StatusForm.Pesquisando}>
            <Grid item xs={12} sx={{ mt: 3 }}>
              <DataTable dados={rsPesquisa} cabecalho={Cabecalho} acoes={
                [
                  // { icone: 'delete', toolTip: 'Excluir', onAcionador: clsCrud.btExcluir },
                  // { icone: 'create', toolTip: 'Alterar', onAcionador: clsCrud.btEditar }
                ]
              } />
            </Grid>
          </Condicional>

          <Condicional condicao={statusForm === StatusForm.Incluindo}>

            <Grid item xs={12} sx={{ mt: 4, textAlign: 'right' }}>
              <Box
                display="flex"
                flexDirection="column"
                gap={1}
                width="100%"
                justifyContent='flex-end'
                sx={{ padding: 2 }}
              >
                <Box display="flex" justifyContent="flex-end">
                  <Typography variant="subtitle2">Vr. Bruto</Typography>
                  <Typography variant="body1" align="right" sx={{ width: '120px' }}>{clsFormato.currency( dados.vrBruto )}</Typography>
                </Box>
                <Box display="flex" justifyContent="flex-end">
                  <Typography variant="body1">{clsFormato.currency( dados.perDesconto )}</Typography>
                  <Typography variant="subtitle2">% Desconto</Typography>
                  <Typography variant="body1" align="right" sx={{ width: '120px' }}>{clsFormato.currency( dados.vrDesconto )}</Typography>
                </Box>
                <Box display="flex" justifyContent="flex-end">
                  <Typography variant="body1">{clsFormato.currency( dados.perAcrescimo )}</Typography>
                  <Typography variant="subtitle2">% Acréscimo</Typography>
                  <Typography variant="body1" align="right" sx={{ width: '120px' }}>{clsFormato.currency( dados.vrAcrescimo )}</Typography>
                </Box>
                <Box display="flex" justifyContent="flex-end">
                  <Typography variant="subtitle2">Valor Líquido</Typography>
                  <Typography variant="body1" align="right" sx={{ width: '120px' }}>{clsFormato.currency( dados.vrLiquido )}</Typography>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} sx={{ mt: 4, textAlign: 'right' }}>
              <Button variant='contained' disabled={rsPedidoItens.length === 0} onClick={() => onConfirmarPedido()}>Confirmar Pedido</Button>
            </Grid>
          </Condicional>
        </Grid>
      </Container>

      <ExibirJSONDev exibir={EMDESENVOLVIMENTO && false} oque={[
        'Dados', dados,
      ]} />

      <ExibirJSONDev exibir={EMDESENVOLVIMENTO && false} oque={[
        'rsERPUnidadeFaturamento', rsERPUnidadeFaturamento,
      ]} />

      <ExibirJSONDev exibir={EMDESENVOLVIMENTO && false} oque={[
        'Limite de Crédito', rsLimiteCredito,
      ]} />

      <ExibirJSONDev exibir={EMDESENVOLVIMENTO && false} oque={[
        'Autorizados a Comprar', rsPessoasAutorizadasComprar
      ]} />

      <ExibirJSONDev exibir={EMDESENVOLVIMENTO && false} oque={[
        'Itens do Pedido', rsPedidoItens
      ]} />

    </>
  )
}