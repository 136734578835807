import React, { useContext, useEffect, useRef, useState } from 'react'
import { ContextoGlobal, ContextoGlobalInterface } from '../../../GlobalStates/ContextoGlobal'
import BackEndAPI from '../../../Services/BackEndAPI'
import { Button, Container, Divider, Grid, IconButton, Paper, Stack, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import CloseIcon from '@mui/icons-material/Close'
import { RespostaPadraoInterface } from '../../../ImportBackend/Interfaces/PadraoInterfaces'
import { MensagemTipo } from '../../../GlobalStates/MensagemState'
import InputText from '../../../DevComponents/InputText'
import ClsValidacao from '../../../Utils/ClsValidacao'
import { PedidoItemInterface, rsERPEstoqueProdutoInterface, rsERPPedidoItemInterface, rsERPProdutoInterface } from '../../../ImportBackend/Interfaces/PedidoInterfaces'
import ExibirJSONDev from '../../../DevComponents/ExibirJSONDev'
import { EMDESENVOLVIMENTO } from '../../../ImportBackend/Config/emDesenvolvimento'
import PesquisarTabela from '../../../DevComponents/PesquisarTabela'
import DataTable, { DataTableCabecalhoInterface } from '../../../DevComponents/DataTable'
import ClsFormatacao from '../../../Utils/ClsFormatacao'
import Condicional from '../../../Layout/Condicional'

interface PropsInterface {
  idUnidadeFaturamento: number
  idCondicaoPagamento: number
  rsPedidoItens: Array<PedidoItemInterface>
  setRsPedidoItens: React.Dispatch<React.SetStateAction<PedidoItemInterface[]>>
  onTotalizarPedido: ( rs: Array<PedidoItemInterface> ) => void
}

interface PesquisaInterface {
  descricao: string
  idProduto: number
  idUnidadeMedida: number
  descricaoProduto: string
}

enum StatusFormEnum {
  PESQUISANDO = 0,
  INCLUINDO_PRODUTO = 1,
  EDITANDO_PRODUTO = 2
}

enum CampoValorEdicaoAtualEnum {
  INICIAL = 0,
  PER_DESCONTO = 1,
  VR_DESCONTO = 2,
  PER_ACRESCIMO = 3,
  VR_ACRESCIMO = 4
}

export default function PedidosItem ( { idUnidadeFaturamento, idCondicaoPagamento, rsPedidoItens, setRsPedidoItens, onTotalizarPedido }: PropsInterface ) {

  const contexto = useContext( ContextoGlobal ) as ContextoGlobalInterface

  const clsFormatos: ClsFormatacao = new ClsFormatacao()

  const { mensagemState, setMensagemState } = ( useContext( ContextoGlobal ) as ContextoGlobalInterface )

  const campoValorEdicaoAtual = useRef<CampoValorEdicaoAtualEnum>( CampoValorEdicaoAtualEnum.INICIAL )

  const abortController: AbortController = new AbortController()

  const navigate = useNavigate()

  const clsApi = new BackEndAPI()

  const [erros, setErros] = useState( {} )

  const [rsStatusForm, setRsStatusForm] = useState<StatusFormEnum>( StatusFormEnum.PESQUISANDO )

  const [rsProdutos, setRsProdutos] = useState<Array<rsERPEstoqueProdutoInterface>>( [] )

  const ResetDados: PedidoItemInterface = {
    idPedidoItem: 0,
    idPedido: 0,
    idEmpresa: 0,
    idCentroEstoque: 0,
    descricaoCentroEstoque: '',
    descricaoProduto: '',
    idProduto: 0,
    perDesconto: 0,
    vrDesconto: 0,
    perAcrescimo: 0,
    vrAcrescimo: 0,
    vrUnitario: 0,
    saldoEstoque: 0,
    permitirEstoqueNegativo: false,
    vrTotal: 0,
    perComissao: 0,
    quantidade: 0,
    desconto: 0,
    vrSugerido: 0,
    vrMinimo: 0,
    vrMaximo: 0,
    idUnidadeMedida: 0,
  }

  const [rsDados, setRsDados] = useState<PedidoItemInterface>( ResetDados )
  const [rsIndiceEdicao, setRsIndiceEdicao] = useState<number>( -1 )

  const ResetPesquisa: PesquisaInterface = {
    descricao: '',
    idProduto: 0,
    idUnidadeMedida: 0,
    descricaoProduto: ''
  }

  const [pesquisa, setPesquisa] = useState<PesquisaInterface>( ResetPesquisa )

  const btFechar = () => {
    navigate( '/' )
  }

  const Cabecalho: Array<DataTableCabecalhoInterface> = [
    {
      cabecalho: 'Estoque',
      campo: 'DESCRICAO',
      alinhamento: 'left'
    },
    {
      cabecalho: 'Saldo',
      campo: 'SALDO_QTD',
      alinhamento: 'right',
      format: ( rs ) => clsFormatos.currency( rs )
    },
    {
      cabecalho: 'Valor',
      campo: 'VALOR_UNITARIO',
      alinhamento: 'right',
      format: ( rs ) => clsFormatos.currency( rs )
    }
  ]
  const CabecalhoItens: Array<DataTableCabecalhoInterface> = [
    {
      cabecalho: 'Produto',
      campo: 'descricaoProduto',
      alinhamento: 'left'
    },
    {
      cabecalho: 'Estoque',
      campo: 'descricaoCentroEstoque',
      alinhamento: 'left'
    },
    {
      cabecalho: 'Qtd',
      campo: 'quantidade',
      alinhamento: 'right',
      format: ( rs ) => clsFormatos.currency( rs )
    },
    {
      cabecalho: 'Vr. Unitário',
      campo: 'vrUnitario',
      alinhamento: 'right',
      format: ( rs ) => clsFormatos.currency( rs )
    },
    {
      cabecalho: '% Desc',
      campo: 'perDesconto',
      alinhamento: 'right',
      format: ( rs ) => clsFormatos.currency( rs )
    },
    {
      cabecalho: '$ Desc',
      campo: 'vrDesconto',
      alinhamento: 'right',
      format: ( rs ) => clsFormatos.currency( rs )
    },
    {
      cabecalho: '% Acréscimo',
      campo: 'perAcrescimo',
      alinhamento: 'right',
      format: ( rs ) => clsFormatos.currency( rs )
    },
    {
      cabecalho: '$ Acréscimo',
      campo: 'vrAcrescimo',
      alinhamento: 'right',
      format: ( rs ) => clsFormatos.currency( rs )
    },
    {
      cabecalho: 'Vr. Total',
      campo: 'vrTotal',
      alinhamento: 'right',
      format: ( rs ) => clsFormatos.currency( rs )
    }
  ]

  const validarDados = (): boolean => {

    let retorno: boolean = true
    let erros: { [key: string]: string } = {}

    let clsValidacao = new ClsValidacao()

    retorno = clsValidacao.naoVazio( 'quantidade', rsDados, erros, retorno )

    if ( retorno ) {
      if ( rsDados.quantidade > rsDados.saldoEstoque && !rsDados.permitirEstoqueNegativo ) {
        erros.quantidade = 'Saldo menor que quantidade'
        retorno = false
      }
    }

    setErros( erros )

    return retorno

  }

  const onChangeProduto = ( rs: rsERPProdutoInterface ) => {

    if ( rs && rs.ID_PRODUTO ) {

      setPesquisa( { ...pesquisa, idProduto: rs.ID_PRODUTO, idUnidadeMedida: rs.ID_UNIDADE_MEDIDA, descricaoProduto: rs.NOME } )

      pesquisarPrecos( rs.ID_PRODUTO )

    }
  }

  const pesquisarPrecos = ( idProduto: number ) => {

    const query: string = `
      getERPEstoqueProdutoValor (
        idProduto: ${idProduto},
        idUnidadeFaturamento: ${idUnidadeFaturamento},
        idRepresentante: ${contexto.loginState.idRepresentante},
        idCondicaoPagamento: ${idCondicaoPagamento}
      ) {
        ID_PRODUTO
        ID_EMPRESA
        ID_CENTRO_ESTOQUE
        DESCRICAO
        SALDO_QTD
        PERMITIR_ESTOQUE_NEGATIVO
        VALOR_UNITARIO
        VALOR_SUGERIDO
        PERC_COMISSAO
        VALOR_MINIMO
        VALOR_MAXIMO
        VALOR_CUSTO
      }
    `

    clsApi.query<Array<rsERPEstoqueProdutoInterface>>( query, 'getERPEstoqueProdutoValor', 'Pesquisando Produtos...', contexto, abortController ).then( ( rs ) => {

      setRsProdutos( rs )

    } ).catch( ( e ) => {

      setMensagemState( {
        cb: null,
        exibir: true,
        exibirBotao: true,
        mensagem: 'Erro na Consulta do ERP - '.concat( e ),
        tipo: MensagemTipo.Error,
        titulo: 'Erro ERP!'
      } )

    } )

    return () => {

      abortController.abort()
    }
  }

  const onEditarProduto = ( indice: number ) => {
    setRsDados( rsPedidoItens[indice] )
    setRsIndiceEdicao( indice )
    setRsStatusForm( StatusFormEnum.EDITANDO_PRODUTO )
  }

  const onApagarProduto = ( indice: number ) => {
    let tmp = [...rsPedidoItens]

    tmp.splice( indice, 1 )

    setRsPedidoItens( tmp )

    onTotalizarPedido( tmp )
  }

  const onAdicionarProduto = ( rs: rsERPEstoqueProdutoInterface ) => {

    let indice = rsPedidoItens.findIndex( v => v.idProduto === rs.ID_PRODUTO && v.idEmpresa === rs.ID_EMPRESA && v.idCentroEstoque === rs.ID_CENTRO_ESTOQUE )

    // Se produto já existe... Se não, inclui um novo...
    if ( indice >= 0 ) {
      setRsDados( rsPedidoItens[indice] )
    } else {

      const tmpDados: PedidoItemInterface = {
        idPedidoItem: 0,
        idPedido: 0,
        idEmpresa: rs.ID_EMPRESA,
        idCentroEstoque: rs.ID_CENTRO_ESTOQUE,
        descricaoCentroEstoque: rs.DESCRICAO,
        descricaoProduto: pesquisa.descricaoProduto,
        idProduto: rs.ID_PRODUTO,
        vrUnitario: rs.VALOR_SUGERIDO,
        saldoEstoque: rs.SALDO_QTD,
        permitirEstoqueNegativo: rs.PERMITIR_ESTOQUE_NEGATIVO,
        vrTotal: 0,
        perComissao: rs.PERC_COMISSAO,
        perDesconto: 0,
        vrDesconto: 0,
        perAcrescimo: 0,
        vrAcrescimo: 0,
        quantidade: 0,
        desconto: 0,
        vrSugerido: rs.VALOR_SUGERIDO,
        vrMinimo: rs.VALOR_MINIMO,
        vrMaximo: rs.VALOR_MAXIMO,
        idUnidadeMedida: pesquisa.idUnidadeMedida
      }

      indice = rsPedidoItens.length

      setRsDados( tmpDados )

      setRsPedidoItens( [...rsPedidoItens, { ...tmpDados }] )
      onTotalizarPedido( [...rsPedidoItens, { ...tmpDados }] )

    }

    setRsIndiceEdicao( indice )

    setRsStatusForm( StatusFormEnum.EDITANDO_PRODUTO )

  }

  const btCancelarItem = () => {
    setRsStatusForm( StatusFormEnum.PESQUISANDO )
  }

  const btConfirmarItem = () => {
    if ( validarDados() ) {

      let tmpItens = [...rsPedidoItens]

      tmpItens[rsIndiceEdicao] = { ...rsDados }

      setRsPedidoItens( tmpItens )
      onTotalizarPedido( tmpItens )
      
      setPesquisa( ResetPesquisa )
      setRsProdutos( [] )

      setRsStatusForm( StatusFormEnum.PESQUISANDO )
    }
  }

  const onAlterarPerDesconto = ( rs: number ) => {

    if ( campoValorEdicaoAtual.current === CampoValorEdicaoAtualEnum.INICIAL ) {

      campoValorEdicaoAtual.current = CampoValorEdicaoAtualEnum.PER_DESCONTO

      let tmpDados = { ...rsDados }

      let percentual: number = typeof rs === 'number' ? rs : 0

      tmpDados.perDesconto = rs
      tmpDados.vrDesconto = parseFloat( ( tmpDados.vrUnitario * percentual / 100 ).toFixed( 2 ) )
      tmpDados.vrTotal = parseFloat( ( ( tmpDados.vrUnitario - tmpDados.vrDesconto ) * tmpDados.quantidade ).toFixed( 2 ) )
      tmpDados.vrAcrescimo = 0
      tmpDados.perAcrescimo = 0

      setRsDados( tmpDados )

    }

  }

  const onAlterarValorDesconto = ( rs: number ) => {

    if ( campoValorEdicaoAtual.current === CampoValorEdicaoAtualEnum.INICIAL ) {

      campoValorEdicaoAtual.current = CampoValorEdicaoAtualEnum.VR_DESCONTO

      let tmpDados = { ...rsDados }

      let percentual: number = typeof rs === 'number' ? parseFloat( ( rs / tmpDados.vrUnitario * 100 ).toFixed( 2 ) ) : 0

      if ( percentual <= contexto.loginState.perDescontoMaximo ) {
        tmpDados.perDesconto = percentual
        tmpDados.vrDesconto = rs
      } else {
        setMensagemState( {
          cb: null,
          exibir: true,
          exibirBotao: true,
          mensagem: 'Desconto Excede o Limite!',
          tipo: MensagemTipo.Warning,
          titulo: 'Atenção!'
        } )
        tmpDados.perDesconto = 0
        tmpDados.vrDesconto = 0
      }

      tmpDados.vrTotal = parseFloat( ( ( tmpDados.vrUnitario - tmpDados.vrDesconto ) * tmpDados.quantidade ).toFixed( 2 ) )
      tmpDados.vrAcrescimo = 0
      tmpDados.perAcrescimo = 0

      setRsDados( tmpDados )

    }

  }

  const onAlterarPerAcrescimo = ( rs: number ) => {

    if ( campoValorEdicaoAtual.current === CampoValorEdicaoAtualEnum.INICIAL ) {

      campoValorEdicaoAtual.current = CampoValorEdicaoAtualEnum.PER_ACRESCIMO

      let tmpDados = { ...rsDados }

      let percentual: number = typeof rs === 'number' ? rs : 0

      tmpDados.perAcrescimo = rs
      tmpDados.vrAcrescimo = parseFloat( ( tmpDados.vrUnitario * percentual / 100 ).toFixed( 2 ) )
      tmpDados.vrTotal = parseFloat( ( ( tmpDados.vrUnitario + tmpDados.vrAcrescimo ) * tmpDados.quantidade ).toFixed( 2 ) )
      tmpDados.vrDesconto = 0
      tmpDados.perDesconto = 0

      setRsDados( tmpDados )

    }

  }

  const onAlterarValorAcrescimo = ( rs: number ) => {

    if ( campoValorEdicaoAtual.current === CampoValorEdicaoAtualEnum.INICIAL ) {

      campoValorEdicaoAtual.current = CampoValorEdicaoAtualEnum.VR_ACRESCIMO

      let tmpDados = { ...rsDados }

      let percentual: number = typeof rs === 'number' ? parseFloat( ( rs / tmpDados.vrUnitario * 100 ).toFixed( 2 ) ) : 0

      tmpDados.perAcrescimo = percentual
      tmpDados.vrAcrescimo = rs
      tmpDados.vrTotal = parseFloat( ( ( tmpDados.vrUnitario - tmpDados.vrAcrescimo ) * tmpDados.quantidade ).toFixed( 2 ) )
      tmpDados.vrDesconto = 0
      tmpDados.perDesconto = 0

      setRsDados( tmpDados )

    }


  }

  useEffect( () => {
    campoValorEdicaoAtual.current = CampoValorEdicaoAtualEnum.INICIAL
  } )

  const onAlterarQuantidade = ( rs: number ) => {

    let quantidade: number = typeof rs === 'number' ? rs : 0

    let tmpDados = { ...rsDados }
    tmpDados.vrTotal = parseFloat( ( ( tmpDados.vrUnitario + tmpDados.vrAcrescimo - tmpDados.vrDesconto ) * quantidade ).toFixed( 2 ) )
    tmpDados.quantidade = quantidade

    setRsDados( tmpDados )

  }

  /*
    useEffect( () => {
  
      pesquisar()
  
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [] )
  
  */


  // 

  return (
    <>

      <Grid container sx={{ display: 'flex', alignItems: 'stretch' }}>

        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', mt: 4 }}>
          <Typography component="h5" variant="h5" align="left">
            Itens do Pedido
            {/*
              <Typography variant="body2" gutterBottom>
              Pesquisa por Código, Auxiliar, Descrição ou Código de Barras.
              </Typography>
              */}
          </Typography>
        </Grid>

        <Condicional condicao={rsStatusForm === StatusFormEnum.PESQUISANDO}>

          <Grid item xs={12}>

            <PesquisarTabela<rsERPProdutoInterface>
              setState={setPesquisa}
              field='ID_PRODUTO'
              fieldSet='idProduto'
              label='Produto'
              dados={pesquisa}
              campoQueryPesquisaID='ID_PRODUTO'
              campoQueryPesquisa='pesquisa'
              camposRetornoQueryPesquisa='{ID_PRODUTO CODIGO_AUXILIAR NOME ATIVO ID_UNIDADE_MEDIDA}'
              campoLabelQueryPesquisa='NOME'
              nomeQueryPesquisa='getERPProduto'
              nomeQueryPesquisaID='getERPProduto'
              mensagemPesquisa='Procurando Produtos...'
              erros={erros}
              valorAtribuirLimpar={0}
              camposParaExibir={['NOME', 'ID_PRODUTO', 'CODIGO_AUXILIAR']}
              onChange={( rs: rsERPProdutoInterface ) => onChangeProduto( rs )}
              inputUpperCase
            />

          </Grid>

          <Grid item xs={12} sx={{ mt: 3 }}>
            <DataTable
              dados={rsProdutos}
              cabecalho={Cabecalho}
              acoes={[
                {
                  icone: 'add',
                  toolTip: '',
                  onAcionador: ( rs: rsERPEstoqueProdutoInterface ) => onAdicionarProduto( rs ),
                  onDisabled: ( rs ) => ( rs.SALDO_QTD <= 0 && !rs.PERMITIR_ESTOQUE_NEGATIVO ) || rs.VALOR_SUGERIDO <= 0
                }
              ]}

            />
          </Grid>

          <Grid item xs={12} sx={{ mt: 3 }}>
            <DataTable
              dados={rsPedidoItens}
              cabecalho={CabecalhoItens}
              acoes={[
                {
                  icone: 'delete',
                  toolTip: '',
                  onAcionador: ( _rs: rsERPEstoqueProdutoInterface, indice ) => onApagarProduto( indice ),
                  onDisabled: ( rs ) => rs.SALDO_QTD <= 0
                },
                {
                  icone: 'edit',
                  toolTip: '',
                  onAcionador: ( _rs: rsERPEstoqueProdutoInterface, indice ) => onEditarProduto( indice ),
                  onDisabled: ( rs ) => rs.SALDO_QTD <= 0
                }
              ]}

            />
          </Grid>

        </Condicional>

        <Condicional condicao={rsStatusForm === StatusFormEnum.EDITANDO_PRODUTO}>

          <Grid item xs={12}>
            <InputText
              dados={rsDados}
              field='descricaoProduto'
              label='Produto'
              disabled={true}
            />
          </Grid>

          <Grid item xs={12}>
            <InputText
              dados={rsDados}
              field='descricaoCentroEstoque'
              label='Estoque'
              disabled={true}
            />
          </Grid>

          <Grid item xs={12}>
            <InputText
              dados={rsDados}
              field='vrUnitario'
              label='Valor'
              tipo='currency'
              disabled={true}
              textAlign='right'
            />
          </Grid>

          <Grid item xs={6} sx={{ pr: 1 }}>
            <InputText
              dados={rsDados}
              field='perDesconto'
              label='% Desconto'
              tipo='currency'
              textAlign='right'
              onChange={( rs: number ) => onAlterarPerDesconto( rs )}
              erros={erros}
            />
          </Grid>

          <Grid item xs={6}>
            <InputText
              dados={rsDados}
              field='vrDesconto'
              label='$ Desconto'
              tipo='currency'
              textAlign='right'
              onChange={( rs: number ) => onAlterarValorDesconto( rs )}
              erros={erros}
            />
          </Grid>

          <Grid item xs={6} sx={{ pr: 1 }}>
            <InputText
              dados={rsDados}
              field='perAcrescimo'
              label='% Acréscimo'
              tipo='currency'
              textAlign='right'
              setState={setRsDados}
              erros={erros}
              onChange={( rs: number ) => onAlterarPerAcrescimo( rs )}
            />
          </Grid>

          <Grid item xs={6}>
            <InputText
              dados={rsDados}
              field='vrAcrescimo'
              label='$ Acréscimo'
              tipo='currency'
              textAlign='right'
              setState={setRsDados}
              erros={erros}
              onChange={( rs: number ) => onAlterarValorAcrescimo( rs )}
            />
          </Grid>

          <Grid item xs={6} sx={{ pr: 1 }}>
            <InputText
              dados={rsDados}
              field='saldoEstoque'
              label='Saldo'
              tipo='currency'
              disabled={true}
              textAlign='right'
            />
          </Grid>

          <Grid item xs={6}>
            <InputText
              dados={rsDados}
              field='quantidade'
              label='Quantidade'
              tipo='currency'
              textAlign='right'
              erros={erros}
              onChange={( rs: number ) => onAlterarQuantidade( rs )}
            />
          </Grid>

          <Grid item xs={12}>
            <InputText
              dados={rsDados}
              field='vrTotal'
              label='Valor Total'
              tipo='currency'
              disabled={true}
              textAlign='right'
            />
          </Grid>

          <Grid item xs={12} sx={{ mt: 2, textAlign: 'right' }}>
            <Stack direction="row" spacing={1} justifyContent="flex-end" alignItems="center">
              <Button variant='contained' onClick={() => btCancelarItem()}>Cancelar</Button>
              <Button variant='contained' onClick={() => btConfirmarItem()}>Confirmar</Button>
            </Stack>
          </Grid>

        </Condicional>

      </Grid>

      <ExibirJSONDev oque={['Pesquisa', pesquisa, 'Produtos', rsProdutos]} exibir={EMDESENVOLVIMENTO && false} />

    </>
  )

}